/*###################################### Edit WP shortcodes from Admin bar ######################################*/

jQuery(document).ready(function ($) {
    Edit_WpSc.init();
});

var Edit_WpSc = (function ($) {
    var init = function () {
        if ($('body.logged-in')[0]) {
            $('#edit_wp_shortcodes').on('change', function () {
                handleEditSc();
            });
        }
    };

    function handleEditSc() {
        if ($('#edit_wp_shortcodes').prop('checked')) {
            $('.shortcode[data-alt^="[wp_shortcode_"]').each(function () {
                if($(this).children().length>0){
                    $(this).wrap("<div class='shortcode heighlited'></div>").attr('title', $(this).attr('data-alt'));
                }else{
                    $(this).wrap("<span class='shortcode heighlited'></span>").attr('title', $(this).attr('data-alt'));
                }
                
            });
        } else {
            $('.shortcode[data-alt^="[wp_shortcode_"]').each(function () {
                $(this).attr('title', '').unwrap();
            });
        }
    }
    return {
        init: init
    }
})(jQuery);


